import {
  ActionMap,
  graphRetailerLocationWorkers,
  location,
  LocationRelations,
  LocationStatus,
  MarketSelectOption,
} from 'types';
import {
  convertEnumToFilterOptions,
  getFilterObjectByValue,
  getFilterObjectByValues,
} from 'utils/helpers/filters';
import { FilterOption, LocationStatusOptions } from 'utils/types/filters';
import { formState, formStateDefaults } from 'utils/types/form';

export type LocationsFilterInterface = {
  status?: Array<FilterOption>;
  active?: Array<FilterOption>;
  markets?: Array<MarketSelectOption>;
  retailers?: Array<FilterOption>;
  type?: Array<FilterOption>;
  payment?: Array<FilterOption>;
  retailerSize?: Array<FilterOption>;
  retailerCategory?: Array<FilterOption>;
  tags?: Array<FilterOption>;
  accountOwner?: Array<FilterOption>;
};

export interface LocationsStateInterface {
  filters: LocationsFilterInterface;
  activeLocation: location;
  activeLocationWorkers: graphRetailerLocationWorkers;
  formState: formState;
  editingRelations?: LocationRelations;
  search?: string;
}

export const filterDefaults = {
  status: getFilterObjectByValues(
    [LocationStatus.ONBOARDED, LocationStatus.ACTIVE],
    convertEnumToFilterOptions(LocationStatus),
  ),
  active: getFilterObjectByValue(
    LocationStatusOptions.ACTIVE,
    convertEnumToFilterOptions(LocationStatusOptions),
  ),
  markets: [],
  payment: [],
  retailers: [],
  type: [],
  retailerSize: [],
  retailerCategory: [],
  tags: [],
  accountOwner: [],
};

export const initialState = {
  activeLocation: {},
  activeLocationWorkers: {},
  editingRelations: null,
  filters: filterDefaults,
  formState: formStateDefaults,
  search: '',
};

export enum LocationsActions {
  CHANGE_FILTER = 'CHANGE_LOCATIONS_FILTER',
  RESET_FILTER = 'RESET_LOCATIONS_FILTER',
  REMOVE_ALL_LOCATIONS = 'REMOVE_ALL_LOCATIONS',
  UPDATE_ACTIVE_LOCATIONS = 'UPDATE_ACTIVE_LOCATION',
  UPDATE_ACTIVE_LOCATION_WORKERS = 'UPDATE_ACTIVE_LOCATION_WORKERS',
  UPDATE_FORM_STATE = 'UPDATE_LOCATION_FORM_STATE',
  UPDATE_FORM_PROCESSING = 'UPDATE_LOCATION_FORM_IS_PROCESSING',
  UPDATE_FORM_SUCCESS = 'UPDATE_LOCATION_FORM_IS_SUCCESS',
  UPDATE_FORM_ERRORS = 'UPDATE_LOCATION_FORM_ERRORS',
  UPDATE_EDITING_RELATIONS = 'UPDATE_EDITING_RELATIONS',
  UPDATE_SEARCH = 'UPDATE_LOCATIONS_SEARCH',
}

type LocationsPayload = {
  [LocationsActions.CHANGE_FILTER]: {
    [key: string]: FilterOption;
  };
  [LocationsActions.RESET_FILTER]: string;
  [LocationsActions.REMOVE_ALL_LOCATIONS]: null;
  [LocationsActions.UPDATE_ACTIVE_LOCATIONS]: location;
  [LocationsActions.UPDATE_ACTIVE_LOCATION_WORKERS]: graphRetailerLocationWorkers;
  [LocationsActions.UPDATE_FORM_PROCESSING]: boolean;
  [LocationsActions.UPDATE_FORM_SUCCESS]: boolean;
  [LocationsActions.UPDATE_FORM_ERRORS]: Array<string>;
  [LocationsActions.UPDATE_EDITING_RELATIONS]: LocationRelations;
  [LocationsActions.UPDATE_SEARCH]: string;
};

type LocationsActionTypes =
  ActionMap<LocationsPayload>[keyof ActionMap<LocationsPayload>];

/* eslint-disable no-case-declarations */
export const locationsReducer = (draft, action: LocationsActionTypes) => {
  const { type } = action;
  switch (type) {
    case LocationsActions.CHANGE_FILTER:
      const key = Object.keys(action?.payload)[0];
      draft.filters[key] = action?.payload[key];
      break;
    case LocationsActions.RESET_FILTER:
      const filter = action?.payload;
      draft.filters[filter] = filterDefaults[filter];
      break;
    case LocationsActions.UPDATE_ACTIVE_LOCATIONS:
      draft.activeLocation = action?.payload;
      break;
    case LocationsActions.UPDATE_ACTIVE_LOCATION_WORKERS:
      draft.activeLocationWorkers = action?.payload;
      break;
    case LocationsActions.REMOVE_ALL_LOCATIONS:
      draft.activeLocation = {};
      break;
    case LocationsActions.UPDATE_FORM_PROCESSING:
      draft.formState.isProcessing = action?.payload;
      break;
    case LocationsActions.UPDATE_FORM_SUCCESS:
      draft.formState.isSuccess = action?.payload;
      break;
    case LocationsActions.UPDATE_FORM_ERRORS:
      draft.formState.errors = action?.payload;
      break;
    case LocationsActions.UPDATE_EDITING_RELATIONS:
      draft.editingRelations = action?.payload;
      break;
    case LocationsActions.UPDATE_SEARCH:
      draft.search = action.payload;
      break;
    default:
      return draft;
  }
  return draft;
};
