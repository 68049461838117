import { user, uuid as uuidType } from '../../types';

export enum UserFlagType {
  POOR_COMMUNICATION = 'POOR_COMMUNICATION',
  NEGATIVE_REVIEW = 'NEGATIVE_REVIEW',
  TARDINESS = 'TARDINESS',
  TARDINESS_PATTERN = 'TARDINESS_PATTERN',
  MISSED_CONFIRMATIONS = 'MISSED_CONFIRMATIONS',
  URGENT_CANCELING = 'URGENT_CANCELING',
  URGENT_CANCELING_PATTERN = 'URGENT_CANCELING_PATTERN',
  INCOMPLETE_PAPERWORK = 'INCOMPLETE_PAPERWORK',
  TERMS_VIOLATION = 'TERMS_VIOLATION',
  OUTDATED_PAPERWORK = 'OUTDATED_PAPERWORK',
  UNPREPAREDNESS = 'UNPREPAREDNESS',
  PROFILE_PHOTO = 'PROFILE_PHOTO',
  OTHER = 'OTHER',
}

export const UserFlagLabelsAndValues = [
  { label: 'Poor Communication', value: UserFlagType.POOR_COMMUNICATION },
  { label: 'Bad Review', value: UserFlagType.NEGATIVE_REVIEW },
  { label: 'Tardiness', value: UserFlagType.TARDINESS },
  {
    label: 'Tardiness (repeat offender)',
    value: UserFlagType.TARDINESS_PATTERN,
  },
  { label: 'Missed Confirmations (Check-ins & Arrivals)', value: UserFlagType.MISSED_CONFIRMATIONS },
  { label: 'Urgent Canceling', value: UserFlagType.URGENT_CANCELING },
  {
    label: 'Urgent Canceling (repeat offender)',
    value: UserFlagType.URGENT_CANCELING_PATTERN,
  },
  { label: 'Incomplete Paperwork', value: UserFlagType.INCOMPLETE_PAPERWORK },
  { label: 'Terms Violation', value: UserFlagType.TERMS_VIOLATION },
  { label: 'Outdated Paperwork', value: UserFlagType.OUTDATED_PAPERWORK },
  { label: 'Unpreparedness', value: UserFlagType.UNPREPAREDNESS },
  { label: 'Profile Photo', value: UserFlagType.PROFILE_PHOTO },
  { label: 'Other', value: UserFlagType.OTHER },
];

export enum UserFlagStatus {
  ACTIVE = 'ACTIVE',
  RESOLVED = 'RESOLVED',
}

export type UserFlag = {
  uuid?: uuidType;
  user?: user;
  flagType?: UserFlagType;
  status?: UserFlagStatus;
  blocking?: boolean;
  flaggedNote?: string;
  resolutionNote?: string;
  flaggedBy?: user;
  resolvedBy?: user;
  expiresAt?: string; // Datetime
  createdAt?: string; // Datetime
  updatedAt?: string; // Datetime
  resolvedAt?: string; // Datetime
};
