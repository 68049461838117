import { shift } from './shift';
import { user } from './user';

export enum ShiftAuditLogEvent {
  BONUS_ADDED = 'BONUS_ADDED',
  MESSAGED_USERS = 'MESSAGED_USERS',
  OVERBOOKING_SLOT_ADDED = 'OVERBOOKING_SLOT_ADDED',
  OVERBOOKING_SLOT_REMOVED = 'OVERBOOKING_SLOT_REMOVED',
  PLAYBOOK_STEP_FAST_FORWARDED = 'PLAYBOOK_STEP_FAST_FORWARDED',
  SHIFT_ACCEPTED_WITH_ATTRIBUTION = 'SHIFT_ACCEPTED_WITH_ATTRIBUTION',
  SHIFT_ACCEPTED_WITHOUT_ATTRIBUTION = 'SHIFT_ACCEPTED_WITHOUT_ATTRIBUTION',
  SHIFT_CANCELLED = 'SHIFT_CANCELLED',
  SHIFT_END_EDITED = 'SHIFT_END_EDITED',
  SHIFT_NOTE_ADDED = 'SHIFT_NOTE_ADDED',
  SHIFT_OVERBOOKED = 'SHIFT_OVERBOOKED',
  SHIFT_START_EDITED = 'SHIFT_START_EDITED',
  WSOS_ADDED = 'WSOS_ADDED',
  WSOS_REMOVED = 'WSOS_REMOVED',
  WORKER_REMOVED_FROM_SHIFT = 'WORKER_REMOVED_FROM_SHIFT',
  WORKERSHIFT_EVENTS_EDITED = 'WORKERSHIFT_EVENTS_EDITED',
}

export type shiftAuditLog = {
  createdAt?: string; // DateTime
  event?: ShiftAuditLogEvent;
  id?: number;
  meta?: string; // JSONString
  shift?: shift;
  timestamp?: string; // DateTime
  user?: user;
};
