import { applyContext, fetchObject, Queries } from 'helpers';
import { paging, ReflexResponse } from 'types';
import { GQL } from 'types/src/routes';

export interface fetchShiftAuditLogsProps extends paging {
  shift?: string; // UUID
  user?: string; // UUID
  since?: string; // Date YYYY-MM-DD
  to?: string; // Date YYYY-MM-DD
}

export const fetchShiftAuditLogs = async (
  data: fetchShiftAuditLogsProps,
  query = Queries.SHIFT_AUDIT_LOGS_QUERY,
  contextOverride?: string,
  returnKey: string | string[] = 'shiftAuditLogs',
): Promise<ReflexResponse> =>
  await fetchObject({
    route: applyContext(
      GQL,
      contextOverride ? contextOverride : 'SHIFT_AUDIT_LOGS_QUERY',
    ),
    query,
    data,
    returnKey,
  });
