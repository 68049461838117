import { CamelToSnakeCaseNested } from './_helpers';
import { graphShift } from './shift';
import { user } from './user';

export enum RoleCategory {
  FRONT_OF_HOUSE = 'Customer Facing',
  BACK_OF_HOUSE = 'Back of House',
  OTHER = 'Other',
}

export type role = {
  id?: string;
  name?: string;
  icon?: string;
  isActive?: boolean;
  isRequestable?: boolean;
  isExperienceRequestable?: boolean;
  priority?: number;
  experienceValue?: number;
  createdAt?: string; // DateTime
  updatedAt?: string; // DateTime
  shifts?: Array<graphShift>;
  reflexuserSet?: Array<user>;
  category?: RoleCategory;
  // endorsements?: [EndorsementNode!]! // Later
  // role?: [WorkerExperienceNode!]! // Later.
};

export type apiRole = CamelToSnakeCaseNested<role>;
