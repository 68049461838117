// BASE ROUTES
export const API_ROOT = process.env.NEXT_PUBLIC_API_ENDPOINT;
export const API_UPLOAD_FILE = `${API_ROOT}/api/upload`;
export const APP_DOMAIN = process.env.NEXT_PUBLIC_APP_DOMAIN || 'localhost';
export const WEB_APP_ROOT = process.env.NEXT_PUBLIC_WEB_APP_ROOT;
export const OPS_APP_ROOT = process.env.NEXT_PUBLIC_OPS_APP_ROOT;
export const MARKETING_APP_ROOT = process.env.NEXT_PUBLIC_MARKETING_APP_ROOT;
export const MAINTENANCE_PAGE = `${MARKETING_APP_ROOT}/maintenance`;

// API ROUTES
export const GQL = `${API_ROOT}/graphql`;
export const API_USERS_ME = `${API_ROOT}/api/users/me`;
export const API_SHIFTS = `${API_ROOT}/api/shifts`;

// EXTERNAL ROUTES
export const MKTG_HOME = '/';
export const MKTG_HOME_SIGNUP = '/?modal=access';
export const WEB_APP_LOGIN = `${WEB_APP_ROOT}/app/login`;
export const WEB_APP_MAIN = `${WEB_APP_ROOT}/app`;
export const ADMIN_LINK = `${API_ROOT}/admin`;
export const ADMIN_VIEW_LINK = `${API_ROOT}/oz/view`;
export const APP_RETAILER_REQUEST = `${WEB_APP_ROOT}/app/request`;
export const APP_RETAILER_CAL = `${WEB_APP_ROOT}/app/shifts`;
export const WEB_APP_SIGNUP = `${WEB_APP_ROOT}/app/signup`;

// INTERNAL ROUTES WEB
export const APP_BASE_URL = '/app';
export const APP_HOME = `${APP_BASE_URL}`;
export const APP_LOGIN = `${APP_BASE_URL}/login`;
export const APP_FORGOT_PASSWORD = `${APP_BASE_URL}/forgot-password`;
export const APP_SET_PASSWORD = `${APP_BASE_URL}/set-password`;
export const APP_SHIFTS = `${APP_BASE_URL}/shifts`;
export const APP_REFER = `${APP_BASE_URL}/refer`;
export const APP_REPORTS = `${APP_BASE_URL}/reports`;
export const APP_WORKER_AVAILABILITY = `${APP_BASE_URL}/availability`;
export const APP_WORKER_SHIFTS = `${APP_BASE_URL}/flex`;
export const APP_WORKER_SHIFT = `${APP_BASE_URL}/flex/[id]`;
export const APP_WORKER_SHIFTS_EXPLORE = `${APP_BASE_URL}/explore`;
export const APP_SETTINGS = `${APP_BASE_URL}/settings`;
export const APP_BUDGETS = `${APP_BASE_URL}/budgets`;
export const APP_BUDGETS_LOCATION = `${APP_BASE_URL}/budgets/[uuid]`;
export const APP_BUDGETS_APPROVALS = `${APP_BASE_URL}/budgets/approvals`;
export const APP_BUDGETS_EDIT = `${APP_BASE_URL}/budgets/edit`;
export const APP_REQUEST = `${APP_BASE_URL}/request`;
export const APP_REFLEXERS = `${APP_BASE_URL}/reflexers`;
export const APP_LOCATIONS = `${APP_BASE_URL}/locations`;
export const APP_GUIDE = `${APP_BASE_URL}/guide`;
export const APP_HELP = `${APP_BASE_URL}/help`;
export const APP_HELP_QUESTION = `${APP_BASE_URL}/help/[slug]`;
export const APP_PROFILE = `${APP_BASE_URL}/profile`;
export const APP_PROFILE_EDIT = `${APP_PROFILE}?innerTab=0&tab=overview&modal=edit-profile`;
export const APP_PROFILE_EDIT_EXPERIENCE = `${APP_PROFILE}?innerTab=1&tab=overview&modal=edit-profile`;
export const APP_START = `${APP_BASE_URL}/start`;
export const APP_ONBOARDING = `${APP_BASE_URL}/onboarding`;
export const APP_ONBOARDING_MARKET = `${APP_ONBOARDING}/market`;
export const APP_ONBOARDING_EXPERIENCE = `${APP_ONBOARDING}/experience`;
export const APP_ONBOARDING_BIRTHDAY = `${APP_ONBOARDING}/birthday`;
export const APP_ONBOARDING_PROFILE = `${APP_ONBOARDING}/profile`;
export const APP_ACTIVATE = `${APP_BASE_URL}/activate`;
export const APP_ACTIVATE_SOCIAL = `${APP_ACTIVATE}/social`;
export const APP_ACTIVATE_BIRTHDAY = `${APP_ACTIVATE}/birthday`;
export const APP_ACTIVATE_ADDRESS = `${APP_ACTIVATE}/address`;
export const APP_ACTIVATE_SKILLS = `${APP_ACTIVATE}/skills`;
export const APP_ACTIVATE_CALL = `${APP_ACTIVATE}/call`;

export const APP_TERMS = '/terms';
export const APP_DOCUMENT = `${APP_BASE_URL}/d/[uuid]`;
export const APP_PRIVACY = '/privacy-policy';
export const APP_REVIEW = `${APP_BASE_URL}/review/[id]`;
export const APP_LOCATION_DETAILS = '/location';
export const APP_FEEDBACK = `${APP_BASE_URL}/feedback/[id]`;
export const APP_WORKER_REVIEW = `${APP_BASE_URL}/feedback/[id]`;
export const APP_WELCOME_GUIDE = `${APP_BASE_URL}/guide`;
export const APP_SIGNUP = `${APP_BASE_URL}/signup`;
export const APP_CONFIRM = `${APP_BASE_URL}/confirm`;
export const APP_WELCOME_VIDEO = `${APP_BASE_URL}/welcome-video`;
export const APP_DOWNLOAD = '/mobile';

export const APP_ROUTES = {
  activate: APP_ACTIVATE,
  activateAddress: APP_ACTIVATE_ADDRESS,
  activateBirthday: APP_ACTIVATE_BIRTHDAY,
  activateCall: APP_ACTIVATE_CALL,
  activateSkills: APP_ACTIVATE_SKILLS,
  activateSocial: APP_ACTIVATE_SOCIAL,
  budgets: APP_BUDGETS,
  budgetsApprovals: APP_BUDGETS_APPROVALS,
  budgetsEdit: APP_BUDGETS_EDIT,
  budgetsLocation: APP_BUDGETS_LOCATION,
  confirm: APP_CONFIRM,
  documents: APP_DOCUMENT,
  feedback: APP_FEEDBACK,
  forgotPassword: APP_FORGOT_PASSWORD,
  guide: APP_GUIDE,
  help: APP_HELP,
  helpQuestion: APP_HELP_QUESTION,
  home: APP_HOME,
  login: APP_LOGIN,
  onboarding: APP_ONBOARDING,
  onboardingBirthday: APP_ONBOARDING_BIRTHDAY,
  onboardingExperience: APP_ONBOARDING_EXPERIENCE,
  onboardingMarket: APP_ONBOARDING_MARKET,
  onboardingProfile: APP_ONBOARDING_PROFILE,
  privacy: APP_PRIVACY,
  profile: APP_PROFILE,
  refer: APP_REFER,
  reports: APP_REPORTS,
  request: APP_REQUEST,
  retailerReflexers: APP_REFLEXERS,
  retailerRequest: APP_REQUEST,
  retailerShifts: APP_SHIFTS,
  review: APP_REVIEW,
  setPassword: APP_SET_PASSWORD,
  settings: APP_SETTINGS,
  signup: APP_SIGNUP,
  start: APP_START,
  terms: APP_TERMS,
  welcomeVideo: APP_WELCOME_VIDEO,
  workerAvailability: APP_WORKER_AVAILABILITY,
  workerExplore: APP_WORKER_SHIFTS_EXPLORE,
  workerShift: APP_WORKER_SHIFT,
  workerShifts: APP_WORKER_SHIFTS,
};

// INTERNAL ROUTES OPS
export const OPS_ACTION_SHIFTS = '/ops?tab=shifts';
export const OPS_ACTIVATE_WORKERS = '/ops?tab=workers';
export const OPS_BRAND = '/brand/[uuid]';
export const OPS_BRANDS = '/brand';
export const OPS_BUDGETS = '/budgets';
export const OPS_CAMPAIGN = '/campaign/[uuid]';
export const OPS_CAMPAIGNS = '/campaign';
export const OPS_CODES = '/codes';
export const OPS_CUSTOMER = '/customer/[uuid]';
export const OPS_HOME = '/';
export const OPS_MAIN = '/ops';
export const OPS_LOCATION = '/location/[uuid]';
export const OPS_LOCATIONS = '/location';
export const OPS_LOGIN = '/login';
export const OPS_ONBOARDINGS = '/ops?tab=onboardings';
export const OPS_ORGANIZATION = '/organization/[uuid]';
export const OPS_REVIEWS = '/reviews';
export const OPS_SETTINGS = '/settings';
export const OPS_SHIFT = '/shift/[uuid]';
export const OPS_SHIFTS = '/shifts';
export const OPS_TAGS = '/tag';
export const OPS_USER = '/user/[uuid]';
export const OPS_USERS = '/user';
export const OPS_WIZARD = '/wizard';
export const OPS_WORKER = '/worker/[uuid]';
export const OPS_WORKERS = '/workers';

export const OPS_ROUTES = {
  actionShifts: OPS_ACTION_SHIFTS,
  activateWorkers: OPS_ACTIVATE_WORKERS,
  brand: OPS_BRAND,
  brands: OPS_BRANDS,
  budgets: OPS_BUDGETS,
  campaign: OPS_CAMPAIGN,
  campaigns: OPS_CAMPAIGNS,
  customer: OPS_CUSTOMER,
  home: OPS_HOME,
  main: OPS_MAIN,
  location: OPS_LOCATION,
  locations: OPS_LOCATIONS,
  login: OPS_LOGIN,
  onboardings: OPS_ONBOARDINGS,
  organization: OPS_ORGANIZATION,
  reviews: OPS_REVIEWS,
  settings: OPS_SETTINGS,
  shift: OPS_SHIFT,
  shifts: OPS_SHIFTS,
  tags: OPS_TAGS,
  user: OPS_USER,
  users: OPS_USER,
  wizard: OPS_WIZARD,
  worker: OPS_WORKER,
  workers: OPS_WORKERS,
};

// INTERNAL ROUTES MARKETING
export const MARKETING_BLOG = '/blog';
export const MARKETING_BLOG_POST = '/blog/[slug]';
export const MARKETING_CAREERS = '/careers';
export const MARKETING_CAREERS_POST = '/careers/[slug]';
export const MARKETING_HOME = '/';
export const MARKETING_PRIVACY = '/privacy-policy';
export const MARKETING_RETAILER_LINKS = '/retailer-links';
export const MARKETING_RETAILERS = '/retail';
export const MARKETING_TERMS = '/terms';
export const MARKETING_WORKERS = '/associate';
export const MARKETING_WORKERS_DYNAMIC = '/associate/[slug]';
export const MARKETING_WORKERS_LOCAL = '/local/[slug]';
export const MARKETING_MAINTENANCE = '/maintenance';

export const MARKETING_ROUTES = {
  blog: MARKETING_BLOG,
  blogPost: MARKETING_BLOG_POST,
  careers: MARKETING_CAREERS,
  careersPost: MARKETING_CAREERS_POST,
  error: 'error',
  home: MARKETING_HOME,
  maintenance: MARKETING_MAINTENANCE,
  privacy: MARKETING_PRIVACY,
  retailerLinks: MARKETING_RETAILER_LINKS,
  retailers: MARKETING_RETAILERS,
  terms: MARKETING_TERMS,
  workers: MARKETING_WORKERS,
  workersDynamic: MARKETING_WORKERS_DYNAMIC,
  workersLocal: MARKETING_WORKERS_LOCAL,
};
