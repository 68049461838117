import { ActionMap, PartialRecord } from 'types';
import { uuid } from 'types/src/common';
import { MarketSelectOption, SelectOption } from 'types/src/form';
import {
  extendedUserDetails,
  graphUserLight,
  UserStatus,
  worker,
} from 'types/src/user';
import {
  convertEnumToFilterOptions,
  getFilterObjectByValue,
} from 'utils/helpers/filters';
import { FilterOption } from 'utils/types/filters';
import { formState, formStateDefaults } from 'utils/types/form';

export const filterDefaults = {
  tiers: [],
  locations: [],
  status: getFilterObjectByValue(
    UserStatus.ACTIVE,
    convertEnumToFilterOptions(UserStatus),
  ),
  markets: [],
  flags: [],
  isFlagged: [],
  hasAddress: [],
  hasExperience: [],
  hasProfileImage: [],
  hasWorked: [],
  isBooked: [],
  retailers: [],
  created: [],
  metabaseQuestions: [],
  onboardedBy: [],
  activatedBy: [],
  isW2Ready: [],
  is1099Ready: [],
  tags: [],
  tagsMissing: [],
  ratingMax: [],
  ratingMin: [],
  priorityMax: [],
  priorityMin: [],
  workedSince: [],
  notWorkedSince: [],
  shiftsCompletedMin: [],
  shiftsCompletedMax: [],
  canScheduleCall: [],
  zipDistance: [],
  zipcode: [],
  zipcodeMaxDistance: [],
  zipcodeMinDistance: [],
  excludeAttributeDormant: [],
  acquisitionCampaigns: [],
};

export enum WorkersTableProperties {
  WORKER = 'Worker',
  TIER = 'Tier',
  TAGS = 'Tags',
  EMAIL = 'Email',
  PHONE_NUMBER = 'Phone Number',
  DATE_JOINED = 'Date Joined',
  ONBOARDING_DATE = 'Onboarding Date',
  SRC = 'Source',
  REFERRER = 'Referrer',
  BIRTHDATE = 'Birthdate',
  PREFERRED_NAME = 'Preferred Name',
  PRONOUNS = 'Pronouns',
  LANGUAGE = 'Language',
  LOCATION = 'Location',
  MARKETS = 'Markets',
  FLAGS = 'Flags',
  ABOUT_ME = 'About',
  HOURS_THIS_WEEK = 'Hours This Week',
  TOTAL_SHIFTS_COMPLETED = 'Total Shifts Completed',
  LAST_SHIFT_DATE = 'Last Shift Date',
  RATING_AVG = 'Rating Avg',
  RATING_COUNT = 'Rating Count',
  CAN_SCHEDULE_CALL = 'Can Schedule Call',
  PUSH_NOTIFICATION_ENABLED = 'Push Notification Enabled',
}

export type WorkersTablePropertiesType = PartialRecord<
  WorkersTableProperties,
  {
    active: boolean;
    canToggle: boolean;
  }
>;

export const defaultVisibleTableProperties = [
  WorkersTableProperties.WORKER,
  WorkersTableProperties.TIER,
];

const defaultTableProperties = Object.keys(WorkersTableProperties).reduce(
  (prev, key) => {
    return {
      ...prev,
      [key]: {
        active: defaultVisibleTableProperties.includes(
          WorkersTableProperties[key],
        ),
        canToggle: !defaultVisibleTableProperties.includes(
          WorkersTableProperties[key],
        ),
      },
    };
  },
  {},
);

export interface WorkersStateInterface {
  selectedWorkers?: worker;
  segments?: {
    favorites: extendedUserDetails[];
    approved: extendedUserDetails[];
    blocked: extendedUserDetails[];
  };
  tableProperties?: WorkersTablePropertiesType;
  filters: {
    tiers?: Array<FilterOption>;
    locations?: Array<FilterOption>;
    status: Array<FilterOption>;
    markets?: Array<MarketSelectOption>;
    flags?: Array<SelectOption>;
    isFlagged?: Array<FilterOption>;
    hasAddress?: Array<FilterOption>;
    hasExperience?: Array<FilterOption>;
    hasProfileImage?: Array<FilterOption>;
    hasWorked?: Array<FilterOption>;
    isBooked?: Array<FilterOption>;
    retailers?: Array<FilterOption>;
    created?: Array<FilterOption>;
    metabaseQuestions?: Array<FilterOption>;
    onboardedBy?: Array<FilterOption>;
    activatedBy?: Array<FilterOption>;
    tags?: Array<FilterOption>;
    tagsMissing?: Array<FilterOption>;
    ratingMax?: Array<FilterOption>;
    ratingMin?: Array<FilterOption>;
    priorityMax?: Array<FilterOption>;
    priorityMin?: Array<FilterOption>;
    workedSince?: Array<FilterOption>;
    notWorkedSince?: Array<FilterOption>;
    shiftsCompletedMin?: Array<FilterOption>;
    shiftsCompletedMax?: Array<FilterOption>;
    canScheduleCall?: Array<FilterOption>;
    zipDistance?: Array<FilterOption>;
    zipcode?: Array<FilterOption>;
    zipcodeMaxDistance?: Array<FilterOption>;
    zipcodeMinDistance?: Array<FilterOption>;
    hasReviews?: Array<FilterOption>;
    excludeAttributeDormant?: Array<FilterOption>;
    acquisitionCampaigns?: Array<FilterOption>;
  };
  formState: formState;
  search?: string;
}

export const initialState: WorkersStateInterface = {
  selectedWorkers: {},
  segments: {
    favorites: [],
    approved: [],
    blocked: [],
  },
  filters: filterDefaults,
  formState: formStateDefaults,
  tableProperties: defaultTableProperties,
  search: '',
};

export enum WorkersActions {
  ADD_WORKER = 'ADD_WORKER',
  ADD_WORKERS = 'ADD_WORKERS',
  REMOVE_WORKER = 'REMOVE_WORKER',
  REMOVE_WORKERS = 'REMOVE_WORKERS',
  REMOVE_ALL_WORKERS = 'REMOVE_ALL_WORKERS',
  CHANGE_FILTER = 'CHANGE_WORKERS_FILTER',
  RESET_FILTER = 'RESET_WORKERS_FILTER',
  RESET_ALL_FILTERS = 'RESET_ALL_WORKERS_FILTER',
  UPDATE_SEGMENT = 'UPDATE_SEGMENT',
  ADD_TO_SEGMENT = 'ADD_WORKER_TO_SEGMENT',
  CLEAR_SEGMENTS = 'CLEAR_WORKERS_SEGMENTS',
  UPDATE_SEARCH = 'UPDATE_USERS_SEARCH',
  RESET_WORKERS_CONTEXT = 'RESET_WORKERS_CONTEXT',
  UPDATE_PROPERTIES = 'UPDATE_WORKER_PROPERTIES',
  ACTIVATE_PROPERTIES = 'ACTIVATE_WORKER_PROPERTIES',
  DEACTIVATE_PROPERTIES = 'DEACTIVATE_WORKER_PROPERTIES',
  UPDATE_FORM_STATE = 'UPDATE_WORKER_FORM_STATE',
  UPDATE_FORM_PROCESSING = 'UPDATE_WORKER_FORM_IS_PROCESSING',
  UPDATE_FORM_SUCCESS = 'UPDATE_WORKER_FORM_IS_SUCCESS',
  UPDATE_FORM_ERRORS = 'UPDATE_WORKER_FORM_ERRORS',
}

type WorkersPayload = {
  [WorkersActions.ADD_WORKER]: Partial<graphUserLight>;
  [WorkersActions.ADD_WORKERS]: Array<Partial<graphUserLight>>;
  [WorkersActions.REMOVE_WORKER]: {
    uuid: string;
  };
  [WorkersActions.REMOVE_WORKERS]: Array<uuid>;
  [WorkersActions.REMOVE_ALL_WORKERS]: null;
  [WorkersActions.CHANGE_FILTER]: {
    [key: string]: FilterOption;
  };
  [WorkersActions.RESET_FILTER]: string;
  [WorkersActions.RESET_ALL_FILTERS]: null;
  [WorkersActions.UPDATE_SEGMENT]: {
    segment: 'favorites' | 'approved' | 'blocked';
    workers: worker[];
  };
  [WorkersActions.ADD_TO_SEGMENT]: {
    segment: 'favorites' | 'approved' | 'blocked';
    worker: worker;
  };
  [WorkersActions.CLEAR_SEGMENTS]: null;
  [WorkersActions.UPDATE_SEARCH]: string;
  [WorkersActions.RESET_WORKERS_CONTEXT]: null;
  [WorkersActions.UPDATE_PROPERTIES]: Array<FilterOption>;
  [WorkersActions.ACTIVATE_PROPERTIES]: Array<FilterOption>;
  [WorkersActions.DEACTIVATE_PROPERTIES]: Array<FilterOption>;
  [WorkersActions.UPDATE_FORM_PROCESSING]: boolean;
  [WorkersActions.UPDATE_FORM_SUCCESS]: boolean;
  [WorkersActions.UPDATE_FORM_ERRORS]: Array<string>;
};

type WorkersActionTypes =
  ActionMap<WorkersPayload>[keyof ActionMap<WorkersPayload>];

export const workersReducer = (draft, action: WorkersActionTypes) => {
  switch (action.type) {
    case WorkersActions.ADD_WORKER:
      draft.selectedWorkers[action?.payload?.uuid] = action?.payload;
      break;
    case WorkersActions.ADD_WORKERS:
      action?.payload.forEach(worker => {
        draft.selectedWorkers[worker.uuid] = worker;
      });
      break;
    case WorkersActions.REMOVE_WORKER:
      delete draft.selectedWorkers[action?.payload.uuid];
      break;
    case WorkersActions.REMOVE_WORKERS:
      action?.payload.forEach(uuid => {
        delete draft.selectedWorkers[uuid];
      });
      break;
    case WorkersActions.REMOVE_ALL_WORKERS:
      draft.selectedWorkers = {};
      break;
    case WorkersActions.CHANGE_FILTER: {
      const key = Object.keys(action?.payload)[0];
      draft.filters[key] = action?.payload[key];
      break;
    }
    case WorkersActions.RESET_FILTER: {
      const filter = action?.payload;
      draft.filters[filter] = filterDefaults[filter];
      break;
    }
    case WorkersActions.RESET_ALL_FILTERS:
      draft.filters = filterDefaults;
      break;
    case WorkersActions.UPDATE_SEGMENT:
      draft.segments[action.payload.segment] = action.payload.workers;
      break;
    case WorkersActions.ADD_TO_SEGMENT: {
      const { segment, worker } = action.payload;
      draft.segments[segment].push(worker);
      break;
    }
    case WorkersActions.CLEAR_SEGMENTS:
      draft.segments.approved = [];
      draft.segments.favorites = [];
      draft.segments.blocked = [];
      break;
    case WorkersActions.UPDATE_SEARCH:
      draft.search = action.payload;
      break;
    case WorkersActions.RESET_WORKERS_CONTEXT:
      draft = initialState;
      break;
    case WorkersActions.UPDATE_PROPERTIES: {
      const updates = action?.payload;
      const newChanges = {};
      for (const update of updates) {
        newChanges[update.value] = {
          canToggle: defaultTableProperties[update.value].canToggle,
          active: true,
        };
      }
      draft.tableProperties = {
        ...defaultTableProperties,
        ...newChanges,
      };
      break;
    }
    case WorkersActions.ACTIVATE_PROPERTIES: {
      const updates = action?.payload;
      for (const update of updates) {
        const property = draft.tableProperties[update.value];
        const canEdit = property.canToggle;
        if (!canEdit) continue;
        property.active = true;
      }
      break;
    }
    case WorkersActions.DEACTIVATE_PROPERTIES: {
      const updates = action?.payload;
      for (const update of updates) {
        const property = draft.tableProperties[update.value];
        const canEdit = property.canToggle;
        if (!canEdit) continue;
        property.active = false;
      }
      break;
    }
    case WorkersActions.UPDATE_FORM_PROCESSING:
      draft.formState.isProcessing = action?.payload;
      break;
    case WorkersActions.UPDATE_FORM_SUCCESS:
      draft.formState.isSuccess = action?.payload;
      break;
    case WorkersActions.UPDATE_FORM_ERRORS:
      draft.formState.errors = action?.payload;
      break;
    default:
      break;
  }
  return draft;
};
