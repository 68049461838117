import { graphShift } from './shift';
import { IntegrationTypes } from './integrations';
import { user } from './user';

export enum PaymentStatus {
  NEW = 'NEW',
  PROCESSING = 'PROCESSING',
  POSTED = 'POSTED',
  PAID = 'PAID',
  ERROR = 'ERROR',
  RESOLVED = 'RESOLVED',
  RETRY = 'RETRY',
}

export enum PaymentType {
  BASE = 'BASE',
  FLEX_FULFILLMENT_INCENTIVE = 'FLEX_FULFILLMENT_INCENTIVE',
  REFERRAL = 'REFERRAL',
  FIRST_FLEX_BONUS = 'FIRST_FLEX_BONUS',
  OTHER_WORKER_ACQUISITION = 'OTHER_WORKER_ACQUISITION',
  FLEX_CANCELLATION_PAYOUT = 'FLEX_CANCELLATION_PAYOUT',
  ADMIN_CANCELLATION_PAYOUT = 'ADMIN_CANCELLATION_PAYOUT',
  MARKETING = 'MARKETING',
  MARKETPLACE_INCENTIVE = 'MARKETPLACE_INCENTIVE',
  OTHER = 'OTHER',
}

export type payment = {
  createdAt?: string; // DateTime
  id?: string;
  isPaused?: boolean;
  notes?: string;
  provider?: IntegrationTypes;
  quantity?: string; // Float
  rate?: string; // Float
  shift?: graphShift;
  status?: PaymentStatus;
  statusMessage?: string;
  type?: PaymentType;
  user?: user;
  updatedAt?: string; // DateTime
};
