import { isNil, omitBy } from 'lodash';
import {
  apiRetailerLocationLight,
  CamelToSnakeCaseNested,
  notificationDateObject,
  SelectOption,
} from 'types';
import { graphExperience, userHistoryItem } from 'types/src/user';
import { isNullDate } from './dates';

/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */

export const filterNullsFromObject = (obj: any): any =>
  Object.keys(obj)
    .filter(e => obj[e] !== null)
    .reduce((o, e) => {
      o[e] = obj[e];
      return o;
    }, {});

export const filterUndefinedsFromObject = (obj: any): any => omitBy(obj, isNil);

export const noNulls = (obj: any): any =>
  filterNullsFromObject(filterUndefinedsFromObject(obj));

export const noEmptyArrays = (obj: any): any =>
  Object.keys(obj).reduce((acc, key) => {
    if (!(Array.isArray(obj[key]) && obj[key].length === 0)) {
      acc[key] = obj[key];
    }
    return acc;
  }, {});

export const noEmpties = (obj: any): any => noNulls(noEmptyArrays(obj));

export const createObjectOfNulls = (keys: string[]) => {
  if (!keys || !keys?.length) return {};
  return keys?.reduce((obj, key) => {
    obj[key] = null;
    return obj;
  }, {});
};

/* eslint-enable @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */

export const queryStringToObject = (str: string): Record<string, string> =>
  Object.assign(
    {},
    ...str.split('&').map(pair => ({
      [pair.split('=')[0]]: pair.split('=')[1],
    })),
  );

export const stripPhoneNumber = (phone: string): string =>
  phone.replace(' ', '').replace('(', '').replace(') ', '').replace('-', '');

export const formatDBPhoneForInput = (phone: string): string =>
  stripPhoneNumber(phone.replace('+1', ''));

export const formatPhoneForDB = (phone: string): string => {
  if (!phone) return '';
  const strippedPhone = stripPhoneNumber(phone);
  const formattedPhone =
    strippedPhone.substring(0, 2) !== '+1'
      ? `+1${strippedPhone}`
      : strippedPhone;
  return formattedPhone;
};

export const formatPhoneForSheets = (phone: string): string => {
  const formattedPhone =
    phone.substring(0, 3) !== '+1 '
      ? phone
      : phone.substring(3, phone.length - 1);
  return formattedPhone;
};

export const formatDBPhoneForDisplay = (phone: string): string => {
  if (!phone) return '';
  const hasPlusOne = phone.startsWith('+1');
  const areaCode = hasPlusOne ? phone.substring(2, 5): phone.substring(0, 3);
  const middle = hasPlusOne ? phone.substring(5, 8): phone.substring(3, 6);
  const end = hasPlusOne ? phone.substring(8, 12): phone.substring(6, 10);
  return `(${areaCode}) ${middle}-${end}`;
};

export function generateRadomDecimal(
  min: number,
  max: number,
  decimalPlaces: number,
): number {
  const random = Math.random() * (max - min) + min;
  const power = Math.pow(10, decimalPlaces);
  return Math.floor(random * power) / power;
}

export const truncate = (str: string, numCharacters: number): string =>
  str.length > numCharacters ? `${str.substring(0, numCharacters)}...` : str;

export function getEnumKeyByValue(
  myEnum: any,
  enumValue: number | string,
): string {
  const keys = Object.keys(myEnum).filter(x => myEnum[x] == enumValue);
  return keys.length > 0 ? keys[0] : '';
}

export function getObjectKey(obj, value) {
  return Object.keys(obj).find(key => obj[key] === value);
}

export function sortArrayOfObjectsByDate(
  objs:
    | apiRetailerLocationLight[]
    | notificationDateObject[]
    | CamelToSnakeCaseNested<any>[]
    | any[],
  key: number | string,
  direction: 'DESC' | 'ASC' = 'DESC',
): Array<any> {
  return objs?.sort((a, b) => {
    const aDate = new Date(a[key]).getTime();
    const bDate = new Date(b[key]).getTime();
    return (aDate > bDate ? 1 : -1) * (direction === 'DESC' ? -1 : 1);
  });
}

export function sortArrayOfObjectsByString(
  objs: SelectOption[],
  key: string,
  direction: 'DESC' | 'ASC' = 'DESC',
): Array<Record<string, unknown>> {
  return objs?.sort((a, b) => {
    const aString = a[key]?.toLowerCase();
    const bString = b[key]?.toLowerCase();
    return (aString > bString ? 1 : -1) * (direction === 'DESC' ? -1 : 1);
  });
}

export function convertArrayOfObjectsWithNullDate(
  objs:
    | number[]
    | userHistoryItem[]
    | graphExperience[]
    | Array<Record<string, string | number | Date>>,
  key: string,
) {
  return objs.map(obj => ({
    ...obj,
    [`new${key.charAt(0).toUpperCase()}${key.slice(1)}`]: isNullDate(
      new Date(obj[key]),
    )
      ? new Date()
      : obj[key],
  }));
}

/**
 * @function limitToMaxDecimalPlaces
 * @summary reduces a float value to a maximum number of decimal values
 * @param {number} num: input
 * @param {number} limit: max number of decimal places to allow
 * @return {number} output
 */
// rounds number "half-up"
// last significant decimal place is 5 or greater, the last significant digit is rounded up, otherwise it is rounded down
export const limitToMaxDecimalPlaces = (num: number, limit: number): number => {
  if (Number.isInteger(num)) return num;
  return parseFloat(num.toFixed(limit));
};

export const removeDuplicatesFromArrayOfObjects = (
  array,
  key: string,
  transform = arrObj => arrObj,
) =>
  array.reduce((arr, item) => {
    const removed = arr.filter(i => transform(i[key]) !== transform(item[key]));
    return [...removed, item];
  }, []);

export const flattenObject = (obj: unknown, parentKey = '') => {
  if (parentKey !== '') parentKey += '.';
  const flattened = {};
  Object.keys(obj).forEach(key => {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      Object.assign(flattened, flattenObject(obj[key], parentKey + key));
    } else {
      flattened[parentKey + key] = obj[key];
    }
  });
  return flattened;
};

export const convertArrayToObjects = (
  arr: Array<unknown>,
  key: string,
): unknown => {
  return arr.reduce((result, obj) => {
    result[obj[key]] = obj;
    return result;
  }, {});
};

export const getAssetPathFromFullUrl = (url: string): string => {
  const URLobj = new URL(url);
  const pathParts = URLobj.pathname.split('/');
  const uploadsIndex = pathParts.indexOf('uploads');
  return `uploads/${pathParts.slice(uploadsIndex + 1).join('/')}`;
};
