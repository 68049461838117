import { uuid } from './common';
import { workershift } from './shift';
import { user } from './user';

export interface graphEndorsement {
  id: string;
  name: string;
  icon: string;
  priority: number;
}
export interface graphGrowthAreas {
  id: string;
  name: string;
  icon: string;
  category: string;
  priority: number;
}

export enum growthCategory {
  SOFT = 'SOFT',
  HARD = 'HARD',
}

export interface graphShiftActivity {
  id: string;
  name: string;
  icon?: string;
}

export enum ReviewStatus {
  NEW = 'NEW',
  IN_PROGRESS = 'IN_PROGRESS',
  INTERNAL_REVIEW = 'INTERNAL_REVIEW',
  PUBLISHED = 'PUBLISHED',
  ARCHIVED = 'ARCHIVED',
}

export const ReviewStatusLabels = {
  [ReviewStatus.NEW]: 'New',
  [ReviewStatus.IN_PROGRESS]: 'In Progress',
  [ReviewStatus.INTERNAL_REVIEW]: 'Internal Review',
  [ReviewStatus.PUBLISHED]: 'Published',
  [ReviewStatus.ARCHIVED]: 'Archived',
} as const;

export enum ReviewType {
  WORKER = 'WORKER',
  RETAILER = 'RETAILER',
}

export enum ReviewPriority {
  BLOCKING = 'BLOCKING',
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  LOW = 'LOW',
  INVISIBLE = 'INVISIBLE',
}

export enum ReviewPrioritization {
  FAVORITE = 1,
  APPROVED = 2,
  BLOCKED = 3,
}

export interface graphReview {
  id?: string;
  uuid?: uuid;
  createdAt?: Date;
  reviewer?: user;
  workershift?: workershift;
  status?: ReviewStatus;
  isUrgent?: boolean;
  priority?: ReviewPriority;
  reviewType?: ReviewType;

  // shared
  rating?: number;
  feedback?: string;

  // retailer
  prioritization?: ReviewPrioritization;
  coaching?: string;
  endorsements?: Array<graphEndorsement>;
  growthAreas?: Array<graphGrowthAreas>;

  // worker
  ratingDetails?: string;
  repeat?: number;
  repeatDetails?: string;
  activities?: Array<graphShiftActivity>;
  activitiesDetails?: string;
  nps?: number;
}

export type review = graphReview;

export interface ReviewsMeta {
  averageRating?: number;
  averagePrioritization?: number;
  completedCount?: number;
  publishedCount?: number;
  internalReviewCount?: number;
  archivedCount?: number;
}
